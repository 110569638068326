import React, { useState, useEffect } from 'react'
import Title from './Title'
import { Link, useParams } from 'react-router-dom'
import { updates } from '../utils/blogUpdates/updatesContent'
import ImageHeader from './ImageHeader'
import '../styles/blogArticle.css'
import TitleSecundary from './TitleSecundary'
import TextBox from './TextBox';
import dot from '../assets/icons/dot.svg'
import SimpleImage from './SimpleImage';
import { Helmet, HelmetProvider } from 'react-helmet-async'

const BlogContent = () => {
    const { id } = useParams()
    const [image, setImage] = useState()
    const [title, setTitle] = useState()
    const [content, setContent] = useState([])

    useEffect(() => {
        function checkArticle() {
            updates.map((item) => {
                if (id === item.id) {
                    setImage(item.image)
                    setTitle(item.title)
                    setContent(item.content)
                }

            })
        }
        checkArticle()
    }, [id, updates])


    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Polarys Chain | {`${title}`}</title>
                    <meta name="description" content="Polarys Foundation, the official portal of the blockchain Polarys Chain." />
                    <meta property="og:title" content={`Polarys Chain | ${title}`} />
                    <meta property="og:description" content="Polarys Foundation, the official portal of the blockchain Polarys Chain." />
                    <meta property="og:image" content={`https://polarys.org${image}`} />
                    <meta property="og:url" content={`https://polarys.org/blog/${id}`} />
                    <meta property="og:type" content="website" />
                </Helmet>
                <div className="container container-blog-article">
                    <div className="article-content-blog">
                        <div className="title-box">
                            <Title title={title} />
                        </div>
                        <div className="header-article-image">
                            <ImageHeader image={image} width={800} height={"100%"} />
                        </div>
                        <div className="content-box-text-box">
                            {content.map((item) => (
                                <React.Fragment>
                                    <div className="title-box-ss">
                                        {item.title && <TitleSecundary width='600px' title={item?.title} />}
                                    </div>
                                    <div className="text-content-box-ss">
                                        {item.content && <TextBox text={item?.content} />}
                                    </div>
                                    <div className="pointer-text-box-ss">
                                        <div className="ss-content-block-text">
                                            {item?.points?.map((item) => (
                                                <div className="li-items">
                                                    <div className="dot-container">
                                                        <SimpleImage image={dot} width={"10px"} height={"10px"} />
                                                    </div>
                                                    <div className="text-li-items">
                                                        {item?.point}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {item?.links && (
                                        <div className="links-container-box">
                                            <div className="blog-container-s-links-s">
                                                <div className="title-box-ss center-flex">
                                                    LINKS
                                                </div>
                                                <div className="links-box-s-content">
                                                    {item?.links?.map((item) => (
                                                        <Link rel="noopener noreferrer" target='_blank' className='links links-blog' to={item?.url}>
                                                            {item?.title}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </HelmetProvider>
        </React.Fragment>
    )
}

export default BlogContent