import React from 'react'
import _logo_code from '../assets/images/code.png'
import _block_ from '../assets/images/block_illustration.png'

const Build = () => {
    return (
        <React.Fragment>
            <div className="_build_section_content">
                <div className="_build_box_s">
                    <div className="_container_build_section">
                        <div className="_img_container_build_box">
                            <img src={_logo_code} alt="_logo_code" className="_img_content_build" />
                        </div>
                        <div className="_container_p_box">
                            <div className="_title_box_build">
                                BUILD ON
                            </div>
                            <div className="_build_data_ds">
                                <div className="S_ww_Rwas">
                                    <img src={_block_} alt="_block_" className="_ssw_eeA" />
                                </div>
                                <div className="SS_Wwwsawe">
                                    <div className="_p_content_s">
                                        Join Polarys to build your decentralized applications (dApps). Develop with tools you already know like Solidity and more. Polarys is your space within the future of blockchain innovation.
                                    </div>
                                    <div className="_p_content_s _s2">
                                        With EVM compatibility, our platform integrates seamlessly with the Ethereum virtual machine, providing greater flexibility and effortless interoperability.

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Build