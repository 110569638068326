import React from 'react';
import whitepaper from "../assets/docs/polarys-whitepaper.pdf";

const PDFViewer = () => {
    return (
        <embed src={whitepaper} type="application/pdf" width="100%" height="619px" padding="0" />
    );
}

export default PDFViewer;
