import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import ImageContainer from '../components/ImageContainer'
import discord from '../assets/icons/discord.svg'
import github from '../assets/icons/github.svg'
import medium from '../assets/icons/medium.svg'
import twitter from '../assets/icons/twitterx.svg'
import '../styles/social.css'
import Title from '../components/Title';
import ContentBox from '../components/ContentBox';
import { Link } from 'react-router-dom';
import banner from "../assets/images/banner_twitter.png"


const Socials = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect is only run once on mount

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Polarys Chain | </title>
          <meta name="description" content="Polarys Foundation, the official portal of the blockchain Polarys Chain." />
          <meta property="og:title" content={`Polarys Chain | Socials`} />
          <meta property="og:description" content="Polarys Foundation, the official portal of the blockchain Polarys Chain." />
          <meta property="og:image" content={`https://polarys.org${banner}`} />
          <meta property="og:url" content={`https://polarys.org/socials`} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="containers containers-socials">
          <div className="box-content-socials">
            <Title title={'Socials'} />
            <div className="content-box-container">
              <ContentBox text={'Connect with our community and explore the spaces we have for you.'} />
            </div>
            <div className="social-page">
              <Link target='_blank' rel='noreferrer' className='links-image disable-link'><ImageContainer image={discord} width={screenWidth < 768 ? "50px" : "90px"} height={screenWidth < 768 ? "50px" : "90px"} /></Link>
              <Link target='_blank' rel='noreferrer' to={'https://github.com/polarysfoundation'} className='links-image'><ImageContainer image={github} width={screenWidth < 768 ? "50px" : "90px"} height={screenWidth < 768 ? "50px" : "90px"} /></Link>
              <Link target='_blank' rel='noreferrer' to={'https://medium.com/@polarysfoundation'} className='links-image'><ImageContainer image={medium} width={screenWidth < 768 ? "50px" : "90px"} height={screenWidth < 768 ? "50px" : "90px"} /></Link>
              <Link target='_blank' rel='noreferrer' to={'https://twitter.com/Polarys_org'} className='links-image'><ImageContainer image={twitter} width={screenWidth < 768 ? "50px" : "90px"} height={screenWidth < 768 ? "50px" : "90px"} /></Link>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </React.Fragment>
  )
}

export default Socials