import React from 'react'
import TextContainer from '../components/TextContainer'
import Title from '../components/Title'

const Policy = () => {
    return (
        <React.Fragment>
            <div className="container content-terms-of-use">
                <div className="container-tos-content-element">
                    <div className="-box-element-text-xsa">
                        <div className="container-header-tos-xa">
                            <div className="title-container-sxa">
                                <Title title={'Privacy Policy'} fontSize={"4rem"} />
                            </div>
                            <div className="update-container-sxa">
                                Last Updated: 08-03-2024
                            </div>
                        </div>
                        <div className="content-terms-x-text">
                            <div className="content-element-text-xs">
                                <TextContainer text={'This Privacy Policy describes how Polarys Foundation ("we," "our," or "Polarys Foundation") collects, uses, and shares personal information of users ("you" or "your") who interact with our interfaces and functions. This policy does not apply to third-party products, services, websites, or other platforms, even if accessed through our Interfaces or Functions. We recommend reviewing the privacy policies of those third parties separately to understand how they handle your information.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={1} bold={true} text={'Acceptance of the Privacy Policy'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'By accessing, interacting, or using any interface or function provided by Polarys Foundation, you agree to the terms and conditions of this Privacy Policy. If you do not agree to these terms, you should not access or use our Interfaces or Functions.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={2} bold={true} text={'Modification of the Privacy Policy'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'We reserve the right to modify this Privacy Policy at any time, and any changes will be effective immediately upon posting on this page. We recommend that you periodically review this Privacy Policy to stay informed about updates. Your continued use of our interfaces or functions after the posting of changes will constitute your acceptance of them.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={3} bold={true} text={'Information We Collect'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'When You Opt for Various Programs, Sign Up to Receive Marketing Information, Send Support Requests/Provide Feedback, or Voluntarily Interact with Us or the Interfaces:'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={3.1} text={'Contact information, such as name, email address, profile picture, username, or social media information.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={3.2} text={'Your preferences for receiving marketing information and communications from us.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={3.3} text={'Your public wallet address when you connect your wallet to access certain features.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={3.4} text={'Automatically Collected Information: We collect certain information automatically, such as your IP address, browser type, pages visited, visit time, and other usage statistics.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={3.5} text={'Information Collected from Third Parties: We may receive information about you from third parties, such as analytics service providers, social networks, or other public sources.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4} bold={true} text={'Use of the Information'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'We use the collected information for the following purposes:'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.1} text={'Provide, maintain, support, and improve our interfaces and functions.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.2} text={'Comply with our legal and regulatory obligations.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.3} text={'Prevent, detect, and investigate fraudulent, abusive, or harmful activities.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.4} text={'Allow you to personalize your experience with our interfaces or functions.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.5} text={'Facilitate events and provide you with information about events.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.6} text={'Provide technical support and respond to your questions and comments.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.7} text={'Comply with the law if we have a legal obligation to collect, use, or retain information about you.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.8} text={'Analyze usage trends to improve our interfaces and functions.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={4.9} text={'Enforce our terms and policies.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={5} bold={true} text={`Children's Information`} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'Our interfaces and functions are not directed to children under the age of 18. We do not intentionally collect personal information from children under the age of 18. If you are under 18 years of age, please do not use our interfaces or functions or provide personal information.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={6} bold={true} text={'Retention of Information'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'We will retain your personal information for as long as necessary to fulfill the purposes set out in this Privacy Policy, unless the law requires or permits us to retain it for a longer period.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={7} bold={true} text={'Links to External Sites'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'Our interfaces or functions may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We recommend reviewing the privacy policies of these websites before providing them with your personal information.'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer index={8} bold={true} text={'Contact'} />
                            </div>
                            <div className="content-element-text-xs">
                                <TextContainer text={'If you have any questions or concerns about this Privacy Policy, you can contact us by sending an email to policy@polarys.org.'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Policy