import React from 'react'
import { Link } from 'react-router-dom'

const NavbarItem = ({ name, description, notAllowed, to, _blank }) => {
    return (
        <React.Fragment>
            <div className={`_content_sslw_Ett`}>
                <div className="_li_ec _ec_ssw">
                    {
                        _blank ? (
                            <Link rel="noopener noreferrer" target='_blank' to={to} className={`_link_refer_navbar _links ${notAllowed && '_not_enabled_ssui_E'}`}>
                                <div className="_tittle_link_box">
                                    <div className="_content_SSwS_Wss_">
                                        <div className="_tittle_s">{name}</div>
                                        <div className="_p_ec_s">{description}</div>
                                    </div>
                                </div>
                            </Link>
                        ) : (
                            <Link to={to} className={`_link_refer_navbar _links ${notAllowed && '_not_enabled_ssui_E'}`}>
                                <div className="_tittle_link_box">
                                    <div className="_content_SSwS_Wss_">
                                        <div className="_tittle_s">{name}</div>
                                        <div className="_p_ec_s">{description}</div>
                                    </div>
                                </div>
                            </Link>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default NavbarItem