import React from 'react'

const TextContainer = ({ index, text, width, height, bold }) => {
    return (
        <React.Fragment>
            <div style={{ width: width, height: height }} className={`container-text-container-with-number`}>
                <div className="text-li-content">
                    {index && (
                        <div className="inter-font  number-text-index">
                            {index}).
                        </div>
                    )}
                    <div className={`inter-font container-text-elements ${bold ? 'bold-text' : ''}`}>
                        {text}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TextContainer