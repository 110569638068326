import React from 'react'
import '../styles/contentbox.css'

const ContentBox = ({ text }) => {
    return (
        <React.Fragment>
            <div className="container content-socials">
                <div className="content-box text-info">
                    {text}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContentBox