import React, { useState, useEffect } from 'react'
import Title from '../components/Title'
import TitleSecundary from '../components/TitleSecundary'
import BlogCover from '../components/BlogCover'
import { updates } from '../utils/blogUpdates/updatesContent'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const Blog = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect is only run once on mount

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Polarys Chain | Blog</title>
          {/*         <meta name="description" content="Descripción general de mi aplicación" /> */}
        </Helmet>
        <div className="container container-blog">
          <div className="content-blog-box">
            <div className="block-title-container">
              <Title title={'Blog'} />
            </div>
            <div className="content-box-info-blog">
              <div className="blog-content-s">
                <div className="content-title-s2">
                  <TitleSecundary title={'Latest Update'} />
                </div>
                <div className="blog-content-box">
                  <BlogCover id={updates[updates.length - 1]?.id} date={updates[updates.length - 1]?.date} width={screenWidth < 768 ? 300 : 400} height={screenWidth < 768 ? 250 : 310} title={updates[updates.length - 1]?.title} description={updates[updates.length - 1]?.content[0].content} image={updates[updates.length - 1]?.image} />
                </div>
              </div>
            </div>
            <div className="content-box-info-blog">
              <div className="blog-content-s">
                <div className="content-title-s2 content-blog-title-all">
                  <TitleSecundary title={'All updates'} />
                </div>
                <div className="blog-content-box content-grid-blog">
                  {updates.map((item) => (
                    <BlogCover id={item?.id} date={item?.date} width={screenWidth < 768 ? 300 : 400} height={screenWidth < 768 ? 250 : 310} title={item?.title} description={item?.content[0].content} image={item?.image} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </React.Fragment>
  )
}

export default Blog