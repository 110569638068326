import React from 'react'
import TextContainer from '../components/TextContainer'
import Title from '../components/Title'
import '../styles/termOfUse.css'

const TermsOfUse = () => {
  return (
    <React.Fragment>
      <div className="container content-terms-of-use">
        <div className="container-tos-content-element">
          <div className="-box-element-text-xsa">
            <div className="container-header-tos-xa">
              <div className="title-container-sxa">
                <Title title={'Terms Of Use'} fontSize={"4rem"} />
              </div>
              <div className="update-container-sxa">
                Last Updated: 08-03-2024
              </div>
            </div>
            <div className="content-terms-x-text">
              <div className="content-element-text-xs">
                <TextContainer text={'These Terms of Use establish the terms and conditions under which you, whether personally or on behalf of an entity ("you" or "your"), are authorized to use, interact with Interfaces or Functions provided by Polarys Foundation (together with its affiliates, "Polarys Foundation", "we", "us", or "our"). These Terms of Use, together with any additional documents and terms or policies attached hereto or expressly incorporated into these Terms of Use by reference, as well as our Privacy Policy (together, the "Terms"), constitute a binding agreement between you and us.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer bold={true} text={'BEFORE WE INCLUDE ANY OTHER DETAILS, WE WANT TO GIVE YOU NOTICE OF SOMETHING UP FRONT: BY AGREEING TO THESE TERMS, YOU AND WE AGREE TO RESOLVE ANY DISPUTES WE MAY HAVE WITH EACH OTHER VIA BINDING ARBITRATION OR IN SMALL CLAIMS COURT (INSTEAD OF A COURT OF GENERAL JURISDICTION), AND YOU AGREE TO DO SO AS AN INDIVIDUAL (INSTEAD OF, FOR EXAMPLE, AS A REPRESENTATIVE OR MEMBER OF A CLASS IN A CLASS ACTION). TO THE EXTENT THAT THE LAW ALLOWS, YOU ALSO WAIVE YOUR RIGHT TO A TRIAL BY JURY. FOR MORE INFORMATION, SEE OUR ARBITRATION AGREEMENT “DISPUTE RESOLUTION, ARBITRATION AGREEMENT, CLASS ACTION WAIVER, AND JURY TRIAL WAIVER.”'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={1} bold={true} text={'Acceptance of the Terms'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer text={'By accessing, interacting, or using any Interface or Function provided by Polarys Foundation, you agree to be bound by these Terms in their entirety. If you do not agree to these Terms, you are not authorized to interact, access, or use any Interface or Function.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={2} bold={true} text={'Interface and Functions'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={2.1} text={'Each Interface hosted by Polarys Foundation offers different functionalities and features, some of which provide information, while others allow users to interact with blockchain networks in a self-managed manner.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={2.2} text={'Polarys Foundation does not facilitate transactions between you and third parties through the interfaces. Polarys Foundation has no access to or control over your cryptocurrency wallets, private keys, or associated passwords.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={3} bold={true} text={'Liability and Risks'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={3.1} text={'You are solely responsible for understanding the risks associated with transactions on blockchain networks, including vulnerabilities of smart contracts, the volatility of crypto assets, and the irreversibility of transactions.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={3.2} text={'Polarys Foundation has no control over the smart contracts or underlying blockchain protocols used in the Interfaces, and shall not be liable for any transaction outcome, including fund losses, failed transactions, or transaction fees.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={3.3} text={'To the extent permitted by applicable law, Polarys Foundation shall not be liable to users or any third party for direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of revenue, profits, data, or reputation, arising from the access or use of the Interfaces, Functions, or their content.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={4} bold={true} text={'Fees and Expenses'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={4.1} text={'All transactions made through the Interfaces may be subject to non-refundable network transaction fees, which shall be borne by you in full.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={5} bold={true} text={'Acceptable Use'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={5.1} text={'You agree to use the Interfaces in a legal and ethical manner, without infringing on the rights of third parties or violating any applicable laws.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={5.2} text={'Polarys Foundation grants users the non-exclusive and non-transferable right to access and use the Interfaces or Functions provided in accordance with these Terms.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={6} bold={true} text={'Modifications and Termination'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={6.1} text={'Polarys Foundation reserves the right to modify, suspend, or discontinue any Interface or Function at any time and without prior notice.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={6.2} text={'Polarys Foundation also reserves the right to modify these Terms at any time by posting the revised terms herein, on any Interface, or Function. Your continued use of our Interfaces or Functions after the posting of changes shall constitute acceptance thereof.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={7} bold={true} text={'Applicable Law'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={7.1} text={'These Terms shall be governed and construed in accordance with the laws of the country of origin of Polarys Foundation, without regard to its provisions on conflict of laws.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={8} bold={true} text={'Copyright'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer text={'At Polarys Foundation, we recognize the importance of protecting copyrights and respecting the intellectual property of third parties. Therefore, we wish to emphasize and reaffirm that:'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={8.1} text={'Copyrights and intellectual property: All materials, content, interfaces, functions, software, design, and other elements provided by Polarys Foundation, whether through its products, websites, interfaces, or other means of communication, are protected by copyrights and other intellectual property laws. These elements are the exclusive property of Polarys Foundation or its licensors, as applicable.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={8.2} text={'Authorized use: Users may access and use the materials and functions provided by Polarys Foundation solely for personal, educational, or non-commercial purposes. Any other use, including, among others, reproduction, distribution, modification, transmission, or public display of these materials, is strictly prohibited without the prior written consent of Polarys Foundation or the corresponding copyright holder.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={8.3} text={'Respect for third-party rights: Users must respect the copyrights and other intellectual property rights of third parties when using any material or content provided by Polarys Foundation. Users must not upload, post, transmit, or distribute any material that violates the copyrights, trademarks, or other intellectual property rights of third parties.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={8.4} text={'Reporting infringements: Polarys Foundation respects the intellectual property rights of third parties and expects users to do the same. If a user believes that their work has been copied in a way that constitutes copyright infringement, they may submit a written notification to Polarys Foundation, including sufficient information to allow us to investigate the alleged infringement.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={9} bold={true} text={'Financial Offers and Advice'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer text={'At Polarys Foundation, we recognize the importance of transparency and clarity in all our communications. Therefore, we wish to emphasize and reaffirm that:'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={9.1} text={'No securities offering: No information provided by Polarys Foundation, whether through its Interfaces, Functions, or any other means of communication, should be construed as an offer to sell or buy securities, or as an invitation to participate in any investment activity.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={9.2} text={'No financial advice offered: Polarys Foundation does not provide financial, legal, tax, or investment advice. The information provided by Polarys Foundation is for informational purposes only and should not be considered as specific advice for your individual circumstances. It is strongly recommended that users seek appropriate professional advice before making financial, investment, or legal decisions.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={9.3} text={'No performance guarantee offered: Participation in blockchain and cryptocurrency-related activities involves inherent risks, and Polarys Foundation does not guarantee specific results or returns from any activity related to its interfaces or functions.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={9.4} text={' User responsibility: By using any interface or function provided by Polarys Foundation, you agree and acknowledge that these activities involve risks and that it is your responsibility to conduct proper due diligence and seek professional advice before participating in any blockchain or cryptocurrency-related activity.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={10} bold={true} text={'Acceptance'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={10.1} text={'By accessing, interacting, or using any Interface or Function provided by Polarys Foundation, you agree to be bound by these Terms in their entirety. If you do not agree to these Terms, you are not authorized to interact, access, or use any Interface or Function.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={11} bold={true} text={'No Relationship or Assignment'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={11.1} text={'Nothing in these Terms shall be construed to create a relationship between you and Polarys Foundation that is not defined herein. Neither you nor Polarys Foundation are agents of each other under these Terms or otherwise, and you shall not hold yourself out in any way as having a relationship with Polarys Foundation other than as someone who uses, accesses, or otherwise interacts with the Interfaces and/or Functions.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={11.2} text={'You agree that you are not authorized to assign or otherwise transfer any of your rights and obligations under these Terms, but Polarys Foundation may assign or transfer the Terms, in whole or in part, without restrictions. Any assignment or transfer in violation of this provision shall be void. Subject to the foregoing, these Terms shall be binding upon the parties and their respective permitted successors and assigns.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={12} bold={true} text={'No Waiver'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={12.1} text={'The failure of Polarys Foundation to enforce any right or provision of these Terms at any given time shall not constitute a waiver of such right or provision.'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={13} bold={true} text={'Contact'} />
              </div>
              <div className="content-element-text-xs">
                <TextContainer index={13.1} text={'If you have any questions about these Terms, please contact us at support@polarys.org.'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TermsOfUse