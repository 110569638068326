import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./containers/Home";
import Navbar from "./components/Navbar";
import "./App.css";
import "./styles/responsive.css";
import ParticleComponent from "./components/ParticleComponent";
import Footer from "./components/Footer";
import Socials from "./containers/Socials";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Blog from "./containers/Blog";
import BlogContent from "./components/BlogContent";
import TermsOfUse from "./containers/TermsOfUse";
import Policy from "./containers/Policy";
import banner from "../src/assets/images/banner_twitter.png"
import PDFViewer from "./components/PDFViewer";

function App() {
  const location = useLocation();

  // Verificar si la ubicación actual corresponde a la ruta del PDF
  const isPDFUrl = location.pathname === '/papers/polarys-whitepaper';

  return (
    <React.Fragment>
      {/* Renderizar el componente PDFViewer solo si la ubicación corresponde a la ruta del PDF */}
      {isPDFUrl ? (
        <Routes>
          <Route path="/papers/polarys-whitepaper" element={<PDFViewer />} />
        </Routes>
      ) : (
        <HelmetProvider>
          <ParticleComponent />
          <Helmet>
            <title>Polarys Chain | Home</title>
            <meta name="description" content="Polarys Foundation, the official portal of the blockchain Polarys Chain." />
            <meta property="og:title" content="Polarys Chain | Home" />
            <meta property="og:description" content="Polarys Foundation, the official portal of the blockchain Polarys Chain." />
            <meta property="og:image" content={`https://polarys.org${banner}`} />
            <meta property="og:url" content="https://polarys.org" />
            <meta property="og:type" content="website" />
          </Helmet>
          <header className="app_header">
            <Navbar />
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/socials" element={<Socials />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogContent />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<Policy />} />
          </Routes>
          <Footer />
        </HelmetProvider>
      )}
    </React.Fragment>
  );
}

export default App;
