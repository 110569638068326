import React from 'react'
/* import background from '../../Assets/Images/_desktop_background.svg'; */
import "../styles/Home.css"
import Main from '../components/Main.js'
import Build from '../components/Build.js'

const Home = () => {
  return (
    <div className='_home_ly_w'>
      <div className="_box_layout_mask_i">
        {/*             <img src={background} alt="background" className="_img_desk_bck" /> */}
      </div>
      <div className="_layout_main_container">
        <Main />
        <Build />
      </div>
    </div>
  )
}

export default Home