import React from 'react'

const SimpleImage = ({ image, width, height }) => {
    return (
        <React.Fragment>
            <div style={{ width: `${width}`, height: `${height}` }} className="container-images-dot">
                <img src={image} alt="" className="images-content" />
            </div>
        </React.Fragment>
    )
}

export default SimpleImage