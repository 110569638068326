const buildingPolarys = require('../../assets/blogImages/buildingPolarys.png')
const whitepaper = require('../../assets/blogImages/whitepaper.png')

const updates = [
    {
        title: 'Building Polarys',
        content: [
            {
                content: `Welcome to Polarys! In this article, we'll explore what Polarys is and what we're building on this exciting blockchain platform.`,
            },
            {
                title: `What is Polarys?`,
                content: `Polarys is a blockchain that offers EVM compatibility and utilizes the Delegated Proof of Stake (DPoS) consensus protocol. We're constantly updating and customizing to develop our own validation system. This system is based on stake and non-mandatory delegation for block production. Additionally, we have a ghost miner mechanism to address potential issues with malicious validators.`,
            },
            {
                title: `What are we building?`,
                content: `We've taken the go-ethereum fork from BNB Chain and modified it for our own purposes. This allows us to develop a separate and different system from any other blockchain using this protocol. Polarys currently has 8 system contracts, but this number could increase over time and with each fork within the chain. This ensures scalability and security for our platform.`,

            },
            {
                content: `The repository of our go-ethereum fork will be available after our launch on the testnet. This will enable support and contribution from the developer community.`,
            },
            {
                content: `Our native token is PLY, used for gas payment and rewards for validators. Additionally, we're developing a non-transferable ERC20 token called sPLY for the staking and delegation system. This token can be obtained by depositing PLY into the Validator Hub contract.`,
            },
            {
                content: `It's important to note that validators must run their own node and remain active to maintain their status. If a validator is inactive for a round, they'll be removed from the system, with a portion of their deposit deducted.`,
            },
            {
                content: `For more information, please contact us via email:`,
            },
            {
                points: [
                    {
                        point: `contact@polarys.org`,
                    },
                    {
                        point: `development@polarys.org`,
                    },
                    {
                        point: `support@polarys.org`,
                    }
                ]
            },
            {
                content: `At Polarys, we're building the future of blockchain technology. Join us on this exciting journey!`
            }
        ],
        image: buildingPolarys,
        date: '06-03-2024',
        id: "c86aa94d-f5b5-4cf6-8cd5-57b91649c6e3"
    },
    {
        title: "Whitepaper",
        content: [
            {
                content: "The launch of our whitepaper V0.1 demonstrates the operational structure of our ecosystem, as well as the emision and tokenomics of PLY, giving way to know the vision of Polarys, clarifying important points within the blockchain project."
            },
            {
                title: "Preview",
                content: "Polarys Chain is a blockchain with an optimized Proof of Stake Delegate (DPoS) consensus protocol that seeks to provide exceptional performance in transaction processing and verification, as well as block generation and verification. Polarys introduces PLY, its native token, which will be used for transaction handling, gas payment and delegation and staking system."
            },
            {
                content: "Polarys aims to facilitate the adoption of Web 3.0 in society, providing scalability, security and strong support to developers and businesses. To achieve this, the Polarys team thoroughly analyzes problems and solutions inside and outside of their ecosystem, in order to offer a continuous improvement of their system hand in hand with the community."
            },
            {
                links: [
                    {
                        title: "PDF",
                        url: "/papers/polarys-whitepaper"
                    },
                    {
                        title: "Gitbook",
                        url: "https://docs.polarys.org"
                    },
                ]
            }

        ],
        image: whitepaper,
        date: "12-04-2024",
        id: "84ea8a4b-6062-45e3-bc40-1a7856d9f198"
    }
]

module.exports = { updates }