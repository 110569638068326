import React from 'react'

const ImageHeader = ({ image, width, height }) => {
    return (
        <React.Fragment>
            <div style={{ width: `${width}px`, height: `${height}` }} className="container-images-header">
                <img src={image} alt="" className="images-content-header" />
            </div>
        </React.Fragment>
    )
}

export default ImageHeader