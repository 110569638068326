import React, { useEffect, useState } from 'react';
import logo from '../assets/images/favicon_logo.png';
import "../styles/Navbar.css"
import { Link } from 'react-router-dom';
import NavbarItem from './NavbarItem';
import bar from '../assets/icons/bars.svg'

const Navbar = () => {
  const [isExploreHovered, setIsExploreHovered] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showResouces, setShowResouces] = useState(false);
  const [showMenu, setShowMenu] = useState(false)

  const handleExploreHover = (index) => {
    if (index == 0) {
      setIsExploreHovered(true);
    } else if (index == 1) {
      setShowJoin(true)
    } else if (index == 2) {
      setShowNetwork(true)
    } else if (index == 3) {
      setShowDoc(true)
    } else if (index == 4) {
      setShowResouces(true)
    }
  };

  const handleExploreLeave = (index) => {
    if (index == 0) {
      setIsExploreHovered(false);
    } else if (index == 1) {
      setShowJoin(false)
    } else if (index == 2) {
      setShowNetwork(false)
    } else if (index == 3) {
      setShowDoc(false)
    } else if (index == 4) {
      setShowResouces(false)
    }
  };

  const handleShowMenu = () => {
    setShowMenu(true)
  }

  const handleHideMenu = () => {
    setShowMenu(false)
  }

  return (
    <React.Fragment>
      <div className={`_navbar_container`}>
        <div className="_navbar_box">
          <div className="_navbar_logo_container">
            <Link to={"/"} className='_links _logo_ls'>
              <div className="_logo_navbar">
                <img src={logo} alt="logo" className="_logo" />
              </div>
              <div className="_logo_name_navbar">
                Polarys
              </div>
            </Link>
          </div>
          <div className={`_navbar_ul ${showMenu ? 'menu-active' : ''}`}>
            <div className="_ul_container">
              <div
                className={`_li_navbar network_sl_`}
                onMouseEnter={() => handleExploreHover(2)}
                onMouseLeave={() => handleExploreLeave(2)}
              >
                <div className="_text_container_s">
                  Network
                </div>
                <div className={`_links_container_  ${showNetwork && '_links_container_oos_w'}`}>
                  <div
                    onMouseEnter={() => handleExploreHover(2)}
                    onMouseLeave={() => handleExploreLeave(2)}
                    className={`_ecosystem_show_d`}>

                    <div className="_ul_ec_">
                      <NavbarItem notAllowed={true} name={"Mainnet"} description={"Polarys Mainnet"} />
                      <NavbarItem notAllowed={true} name={"Testnet"} description={"Polarys Testnet"} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`_li_navbar explore_sl_`}
                onMouseEnter={() => handleExploreHover(0)}
                onMouseLeave={() => handleExploreLeave(0)}
              >
                <div className="_text_container_s">
                  Explore
                </div>
                <div className={`_links_container_  ${isExploreHovered && '_links_container_oos_w'}`}>
                  <div
                    onMouseEnter={() => handleExploreHover(0)}
                    onMouseLeave={() => handleExploreLeave(0)}
                    className={`_ecosystem_show_d`}>

                    <div className="_ul_ec_">
                      <NavbarItem notAllowed={true} name={"Ecosystem"} description={"discover the dapps built in Polarys."} />
                      <NavbarItem to={'/blog'} name={"Blog"} description={"the latest Polarys news"} />
                    </div>
                  </div>
                </div>

              </div>
              <div
                onMouseEnter={() => handleExploreHover(1)}
                onMouseLeave={() => handleExploreLeave(1)}
                className="_li_navbar join_sl_">
                <div className="_text_container_s">
                  Community
                </div>
                <div className={`_links_container_  ${showJoin && '_links_container_oos_w'}`}>
                  <div
                    onMouseEnter={() => handleExploreHover(1)}
                    onMouseLeave={() => handleExploreLeave(1)}
                    className={`_ecosystem_show_d`}>

                    <div className="_ul_ec_">
                      <NavbarItem to={"/socials"} name={"Socials"} description={"Join our community, and follow us on our social networks to not miss any updates."} />
                      <NavbarItem notAllowed={true} name={"Governance"} description={"participates in shaping the Polarys protocol"} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="_li_navbar about_sl_"
                onMouseEnter={() => handleExploreHover(3)}
                onMouseLeave={() => handleExploreLeave(3)}
              >
                <div className="_text_container_s">
                  Developers
                </div>
                <div className={`_links_container_  ${showDoc && '_links_container_oos_w'}`}>
                  <div
                    onMouseEnter={() => handleExploreHover(3)}
                    onMouseLeave={() => handleExploreLeave(3)}
                    className={`_ecosystem_show_d`}>

                    <div className="_ul_ec_">
                      <NavbarItem _blank={true} name={"Documentation"} description={"Explore our documentation and wiki"} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="_li_navbar resource_sl_"
                onMouseEnter={() => handleExploreHover(4)}
                onMouseLeave={() => handleExploreLeave(4)}
              >
                <div className="_text_container_s">
                  Resources
                </div>
                <div className={`_links_container_  ${showResouces && '_links_container_oos_w'}`}>
                  <div
                    onMouseEnter={() => handleExploreHover(4)}
                    onMouseLeave={() => handleExploreLeave(4)}
                    className={`_ecosystem_show_d`}>

                    <div className="_ul_ec_">
                      <NavbarItem _blank={true} to={"/papers/polarys-whitepaper"} name={"Whitepaper"} description={"$PLY Utility and Tokenomics"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-icon-responsive">
            <div onClick={handleShowMenu} className="image-navbar-container-ss">
              <img src={bar} alt="" className="navbar-responsive" />
            </div>
          </div>
          <div onClick={handleHideMenu} className={`close-button-swe ${showMenu ? 'menu-active' : ''}`}>
            <div className="-container-x-button">
              X
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar;
