import React from 'react'
import '../styles/footer.css'
import logo from '../assets/images/favicon_logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-content">
        <div className="container container-footer-content">
          <div className="links-footer-box">
            <div className="header-footer-content">
              <div className="logo-header-footer">
                <div className="container-image-footer image-container">
                  <img src={logo} alt="" className="logo-footer" />
                </div>
                <div className="content-title-logo footer-text">
                  Polarys
                </div>
              </div>

            </div>
            <div className="container footer-box-info">
              <div className="_content_ info-links">
                <div className="content-title">
                  Network
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    Mainnet
                  </div>
                  <div className="link-container footer-li-links">
                    Testnet
                  </div>
                </div>
              </div>
              <div className="_content_ info-links">
                <div className="content-title">
                  Explore
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    Ecosystem
                  </div>
                  <div className="link-container footer-li-links">
                    <Link className='links links-footer' to={'/blog'}>
                      Blog
                    </Link>
                  </div>
                </div>
              </div>
              <div className="_content_ info-links">
                <div className="content-title">
                  Community
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    <Link className='links links-footer' to={'/socials'}>
                      Social
                    </Link>
                  </div>
                  <div className="link-container footer-li-links">
                    Governance
                  </div>
                </div>
              </div>
              <div className="_content_ info-links">
                <div className="content-title">
                  Devolpers
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    <Link rel="noopener noreferrer" target='_blank' className='links links-footer' to={'https://docs.polarys.org'}>
                      Documentation
                    </Link>
                  </div>

                </div>
              </div>
              <div className="_content_ info-links">
                <div className="content-title">
                  Resources
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    <Link rel="noopener noreferrer" target='_blank' className='links links-footer' to={'/papers/polarys-whitepaper'}>
                      Whitepaper
                    </Link>
                  </div>
                </div>
              </div>
              <div className="_content_ info-links">
                <div className="content-title">
                  Legal
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    <Link className='links links-footer' to={'/terms-of-use'}>
                      Terms Of Use
                    </Link>
                  </div>
                </div>
                <div className="content-li-info footer-li">
                  <div className="link-container footer-li-links">
                    <Link className='links links-footer' to={'/privacy-policy'}>
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-copyright-box">
          <div className="content-title footer-copyright">
            © 2024 Polarys Foundation  | All rights reserved
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer