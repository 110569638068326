import React from 'react'
import '../styles/blogcover.css'
import { Link } from 'react-router-dom'

const BlogCover = ({ image, description, title, width = 0, height = 0, date, id }) => {
    return (
        <React.Fragment>
            <div className="content-blog-cover">
                <div className="content-blog-updates" style={{ width: `${width}px`, height: `${height}px` }}>
                    <Link to={`/blog/${id}`} className='links link-content-blog'>
                        <div className="cover-updates">
                            <div className="image-container-update">
                                <img src={image} alt="" className="image-content-blog-update" />
                            </div>

                            <div className="box-container-info">
                                <div className="content-info-s-x-blog-cover">
                                    <div className="container-title-box">
                                        <div className="container-title-index bold-text">
                                            Title:
                                        </div>
                                        <div className="container-title-blog-update">
                                            {title}
                                        </div>
                                    </div>
                                    <div className="date-box-x">
                                        <div className="black-text container-date-title-x bold-text">
                                            Date:
                                        </div>
                                        <div className="black-text content-date-x">
                                            {date}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-description-update">
                                    <div className="update-content gray-color bold-text">
                                        {description?.substring(0, 40)}...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BlogCover