import React from 'react'

const TextBox = ({ text }) => {
    return (
        <React.Fragment>
            <div className="container-content-box">
                <div className="text-box info-container">
                    <div className="text-box-content">
                        {text}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TextBox