// src/reportWebVitals.js
import { getCLS, getFID, getFCP, getLCP, getTTFB } from "web-vitals";

const sendToAnalytics = (metric) => {
  // Replace this function with your own analytics service, e.g., Google Analytics
  console.log(metric);
};

const reportWebVitals = () => {
  getCLS(sendToAnalytics);
  getFID(sendToAnalytics);
  getFCP(sendToAnalytics);
  getLCP(sendToAnalytics);
  getTTFB(sendToAnalytics);
};

export default reportWebVitals;