import React from 'react'
import '../styles/title.css'

const Title = ({ title, fontSize }) => {
    return (
        <React.Fragment>
            <div className="container-title-general">
                <div className="content-title general-info" style={{ fontSize: `${fontSize}` }}>
                    {title}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Title