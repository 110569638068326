import React from 'react'
import _bk_logo from '../assets/images/banner_logo.png'
import "../styles/Main.css"
import "boxicons"
import { Link } from 'react-router-dom'

const Main = () => {
    return (
        <React.Fragment>
            <div className="_container_main_layout">
                <div className="_j_sw_main_box">
                    <div className="_main_layout_cbox fade-in">
                        <span className="_chain_p_s">
                            POL
                        </span>
                        <span className="_chain_p_d">
                            ARYS
                        </span>
                        <span className="_chain_p_b">
                            CHAIN
                        </span>
                    </div>
                    <div className="_content_p_main_box">
                        Polarys is at the forefront of blockchain innovation, with our advanced consensus protocol known as DPoS (Delegated Proof Of Stake). Our ecosystem places a strong emphasis on scalability and security, driven by an active community of PLY holders, the native Polarys token.
                    </div>
                    <div className="_container_button">
                        <div className="_button_box_built _build_in">

                            <Link className='_btn_'><div className="_button_built">BUILD IN POLARYS <box-icon id="_arrow_left_built_btn" color="white" name='chevron-right'></box-icon> </div></Link>
                        </div>
                        <div className="_button_box_built _whitepaper">

                            <Link to={"/papers/polarys-whitepaper"} rel="noopener noreferrer" target='_blank' className='_btn_'><div className="_button_built">WHITEPAPER<box-icon id="_arrow_left_built_btn" color="white" name='chevron-right'></box-icon> </div></Link>
                        </div>
                    </div>
                </div>
                <div className="_img_logo_main_sa">
                    <img src={_bk_logo} alt="_bk_logo" className="_main_logo" />
                </div>
            </div>



        </React.Fragment>
    )
}

export default Main