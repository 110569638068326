import React from 'react'
import '../styles/titleSecundary.css'

const TitleSecundary = ({ title, width = "auto" }) => {
    return (
        <React.Fragment>
            <div style={{width: width}} className="container-title-secundary">
                <div className="content-title-ss">
                    {title}
                </div>
            </div>
        </React.Fragment>
    )
}

export default TitleSecundary