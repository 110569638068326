import React, { useEffect, useRef } from 'react';

const ParticleComponent = () => {
  const overlayRef = useRef(null);
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const particlesRef = useRef([]);

  useEffect(() => {
    const overlay = overlayRef.current;
    const canvas = document.createElement('canvas');
    overlay.appendChild(canvas);

    const ctx = canvas.getContext('2d');

    canvasRef.current = canvas;
    ctxRef.current = ctx;

    const particles = [];

    const Particle = class {
      constructor(x, y, size) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.speedX = (Math.random() - 0.5) * 1.5;
        this.speedY = (Math.random() - 0.5) * 1.5;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.size > 0.2) this.size -= 0.1;

        if (this.x < 0 || this.x > canvas.width) {
          this.speedX = -this.speedX;
        }
        if (this.y < 0 || this.y > canvas.height) {
          this.speedY = -this.speedY;
        }
      }

      draw() {
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
      }
    };

    const createParticles = (count) => {
      for (let i = 0; i < count; i++) {
        particles.push(new Particle(
          Math.random() * canvas.width,
          Math.random() * canvas.height,
          Math.random() * 5 + 1
        ));
      }
    };

    particlesRef.current = particles;

    const drawParticles = () => {
      particles.forEach((particle) => {
        particle.update();
        particle.draw();

        particles.forEach((otherParticle) => {
          const distance = Math.sqrt(
            Math.pow(otherParticle.x - particle.x, 2) +
            Math.pow(otherParticle.y - particle.y, 2)
          );

          if (distance < 50) {
            ctx.beginPath();
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
            ctx.stroke();
          }
        });
      });
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawParticles();
      requestAnimationFrame(animate);
    };

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    createParticles(window.innerWidth < 768 ? 50 : 100);

    document.body.appendChild(overlay);

    animate();

    window.addEventListener('resize', setCanvasSize);

    const handleTouchStart = (event) => {
      event.preventDefault(); // Evita el comportamiento predeterminado del evento de toque
    };

    overlay.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      overlay.removeEventListener('touchstart', handleTouchStart);
      document.body.removeChild(overlay);
    };
  }, []);

  return <div ref={overlayRef} style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', zIndex: -1, width: '100%', height: '100%' }} />;
};

export default ParticleComponent;
